<template>
  <vx-card :title="title">
    <!-- <vs-button
      class="mt-2"
      color="primary"
      type="border"
      icon-pack="feather"
      icon="icon-plus"
      @click="handleCreate()"
      >Create</vs-button
    > -->
    <div class="vx-row">
      <div class="vx-col">
        <vs-button class="ml-4 mt-2" type="border" color="danger" v-on:click="handleClose"
          >Back</vs-button
        >
      </div>
      <div class="vx-col">
        <vs-button
          class="ml-4 mt-2"
          type="border"
          color="success"
          v-on:click="handleExcel"
          >Excel</vs-button
        >
      </div>
    </div>
    <div class="vx-row mt-12">
      <div class="vx-col w-1/3">
        <label class="vs-input--label">PO Ref Child No</label>
        <vs-input
          class="w-full"
          type="text"
          readonly
          :value="dataCo.code ? dataCo.code : dataCo.ex_code"
        />
      </div>
    </div>
    <div class="vx-row mt-2">
      <div class="vx-col w-1/3">
        <label class="vs-input--label">Salesman</label>
        <vs-input
          class="w-full"
          type="text"
          readonly
          :value="dataCo.personal_code_external + ' - ' + dataCo.personal_name"
        />
      </div>
    </div>
    <div class="vx-row mt-2">
      <div class="vx-col w-1/3">
        <label class="vs-input--label">Ship To</label>
        <vs-input
          class="w-full"
          type="text"
          readonly
          :value="dataCo.ship_to_code + ' - ' + dataCo.ship_to_name"
        />
      </div>
    </div>
    <div class="vx-row mt-2">
      <div class="vx-col w-1/2">
        <label class="vs-input--label">Incoterms</label>
        <multiselect
          class="selectExample"
          v-model="selectIncoterms"
          :options="optionIncoterms"
          :multiple="false"
          :allow-empty="false"
          :group-select="false"
          :max-height="100"
          :limit="3"
          placeholder="Type to search"
          :searchable="true"
          :custom-label="customLableIncoterms"
        >
        </multiselect>
      </div>
      <div class="vx-col w-1/2" style="display: flex">
        <vs-button
          class="ml-4 mt-2"
          type="border"
          color="success"
          style="align-self: flex-end"
          v-on:click="handleSubmitEditIncoterms"
          >Submit</vs-button
        >
      </div>
    </div>
    <div class="vx-row mb-12">
      <div v-bind:class="[detail ? detailShow : '', detailHide]">
        <vs-table stripe border :sst="true" :data="table.data">
          <template slot="thead">
            <!-- <vs-th v-if="is_combo"></vs-th> -->
            <vs-th>No</vs-th>
            <vs-th>SKU Code</vs-th>
            <vs-th>SKU Name</vs-th>
            <vs-th>Divison Code</vs-th>
            <vs-th>Qty Order</vs-th>
            <vs-th>Base Price</vs-th>
            <vs-th>Disc</vs-th>
            <vs-th>Total</vs-th>
          </template>

          <template v-for="(dt, i) in table.data">
            <!-- <div :key="i" v-for="(dt, i) in data"> -->
            <vs-tr :data="tr" :key="indextr + i" v-for="(tr, indextr) in dt.detail_hu">
              <vs-td v-if="is_combo && !tr.is_combo"></vs-td>
              <vs-td>{{ i + indextr + 1 }}</vs-td>
              <vs-td
                >{{ tr.sku_code }}
                <br />
                <span v-if="dt.is_free_good" class="badge badge-pill badge-success"
                  >Free Good</span
                ></vs-td
              >
              <vs-td>{{ tr.item_name }}</vs-td>
              <!-- <vs-td>{{ data[indextr].item_unit.item.name }}</vs-td> -->
              <vs-td>
                {{ tr.division_code }}
              </vs-td>
              <vs-td>
                {{ (tr.is_combo ? dt.quantity_combo : tr.quantity) + " " + tr.unit_name }}
              </vs-td>
              <vs-td v-if="dt.is_free_good"> </vs-td>
              <vs-td v-else>
                {{ (tr.price > 0 ? tr.price : "") | formatCurrency }}
              </vs-td>
              <vs-td>
                {{ (tr.total_discount > 0 ? tr.total_discount : "") | formatCurrency }}
              </vs-td>
              <vs-td>
                {{ (tr.price > 0 ? tr.price - tr.total_discount : "") | formatCurrency }}
              </vs-td>
              <template slot="expand" v-if="tr.is_combo">
                <vs-table
                  :style="{ width: '100%' }"
                  stripe
                  border
                  :sst="false"
                  :data="tr.detail_combo"
                  :searchable="false"
                >
                  <template slot="thead">
                    <vs-th></vs-th>
                    <vs-th></vs-th>
                    <vs-th>SKU Code</vs-th>
                    <vs-th>SKU Name</vs-th>
                    <vs-th>Divison Code</vs-th>
                    <vs-th>Qty Order</vs-th>
                    <vs-th>Base Price</vs-th>
                    <vs-th>Disc</vs-th>
                    <vs-th>Total</vs-th>
                  </template>

                  <template slot-scope="{ data }">
                    <vs-tr :data="tr2" :key="indextr2" v-for="(tr2, indextr2) in data">
                      <vs-td></vs-td>
                      <vs-td>{{ i + indextr + 1 + "." + (indextr2 + 1) }}</vs-td>
                      <vs-td>{{ tr2.SkuCode }}</vs-td>
                      <vs-td>{{ tr2.ItemName }}</vs-td>
                      <!-- <vs-td>{{ data[indextr].item_unit.item.name }}</vs-td> -->
                      <vs-td>
                        {{ tr2.DivisionCode }}
                      </vs-td>
                      <vs-td>
                        {{ tr2.Quantity + " " + tr2.UnitName }}
                      </vs-td>
                      <vs-td>
                        {{
                          (tr2.BasePrice > 0 ? tr2.BasePrice.toString() : "")
                            | formatCurrency
                        }}
                      </vs-td>
                      <vs-td>
                        {{
                          (tr2.TotalDiscount > 0 ? tr2.TotalDiscount : "")
                            | formatCurrency
                        }}
                      </vs-td>
                      <vs-td>
                        {{ (tr2.Price > 0 ? tr2.Price : "") | formatCurrency }}
                      </vs-td>
                    </vs-tr>
                  </template>
                </vs-table>
              </template>
            </vs-tr>
            <vs-tr v-if="!dt.detail_hu" :data="dt" :key="i">
              <vs-td>{{ i + indextr + 1 }}</vs-td>
              <vs-td>{{ data.sku_code }}</vs-td>
              <vs-td>{{ data.item_name }}</vs-td>
              <!-- <vs-td>{{ data[indextr].item_unit.item.name }}</vs-td> -->
              <vs-td>
                {{ data.division }}
              </vs-td>
              <vs-td>
                {{ dt.quantity + " " + dt.unit_name }}
              </vs-td>
              <vs-td>
                {{
                  (data.base_price > 0 ? data.base_price.toString() : "") | formatCurrency
                }}
              </vs-td>
              <vs-td>
                {{ (data.discount > 0 ? data.discount : "") | formatCurrency }}
              </vs-td>
              <vs-td>
                {{ (data.total > 0 ? data.total : "") | formatCurrency }}
              </vs-td>
            </vs-tr>
            <!-- </div> -->
          </template>
        </vs-table>
        <vs-table :data="[{}]" :sst="false">
          <template>
            <vs-tr
              ><vs-td><div></div> </vs-td
            ></vs-tr>
            <vs-tr class="footer-customer-order-top-line">
              <vs-td><span class="p-12"></span></vs-td>
              <vs-td><span class="p-12"></span></vs-td>
              <vs-td><span class="p-12"></span></vs-td>
              <vs-td><span class="p-12"></span></vs-td>
              <vs-td>Total Order</vs-td>
              <vs-td>{{ totalOrder.toString() | formatCurrency }}</vs-td>
            </vs-tr>
            <vs-tr class="footer-customer-order">
              <vs-td> </vs-td>
              <vs-td> </vs-td>
              <vs-td> </vs-td>
              <vs-td> </vs-td>
              <vs-td>Total Discount</vs-td>
              <vs-td>{{ TotalDisc.toString() | formatCurrency }}</vs-td>
            </vs-tr>
            <vs-tr class="footer-customer-order">
              <vs-td> </vs-td>
              <vs-td> </vs-td>
              <vs-td> </vs-td>
              <vs-td> </vs-td>
              <vs-td>Gross Amount</vs-td>
              <vs-td>{{ grossAmount.toString() | formatCurrency }}</vs-td>
            </vs-tr>
            <vs-tr class="footer-customer-order">
              <vs-td> </vs-td>
              <vs-td> </vs-td>
              <vs-td> </vs-td>
              <vs-td> </vs-td>
              <vs-td>Diskon Nota</vs-td>
              <vs-td>{{ discNote.toString() | formatCurrency }}</vs-td>
            </vs-tr>
            <vs-tr class="footer-customer-order">
              <vs-td> </vs-td>
              <vs-td> </vs-td>
              <vs-td> </vs-td>
              <vs-td> </vs-td>
              <vs-td>Tax</vs-td>
              <vs-td>{{ tax.toString() | formatCurrency }}</vs-td>
            </vs-tr>
            <vs-tr class="footer-customer-order">
              <vs-td> </vs-td>
              <vs-td> </vs-td>
              <vs-td> </vs-td>
              <vs-td> </vs-td>
              <vs-td style="font-size: 1.1rem !important; font-weight: bold !important"
                >Netto</vs-td
              >
              <vs-td style="font-size: 1.1rem !important; font-weight: bold !important">{{
                netto.toString() | formatCurrency
              }}</vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </div>
    </div>
  </vx-card>
</template>

<script>
import moment from "moment";
export default {
  data() {
    return {
      baseUrl: "/api/sfa/v1/customer-order/detail/",
      detailShow: "core vx-col md:w-1/2 w-full mb-base ",
      detailHide: "core vx-col md:w-1/1 w-full mb-base ",
      title: "Detail Sales Order",
      detail: false,
      deleteId: null,
      table: this.tableDefaultState(),
      totalOrder: 0,
      TotalDisc: 0,
      grossAmount: 0,
      is_combo: false,
      discNote: 0,
      selectIncoterms: {
        id: 0,
        text: "",
      },
      optionIncoterms: [],
      tax: 0,
      netto: 0,
      id: 0,
    };
  },
  props: ["coID", "dataCo"],
  methods: {
    tableDefaultState() {
      return {
        data: [],
        length: 10,
        page: 1,
        search: "",
        order: "id",
        sort: "desc",
        total: 0,
        totalpage: 1,
        totalSearch: 0,
        limits: [10, 25, 50, 100, "All"],
        start: 1,
        end: 0,
      };
    },
    handleSearch(searching) {
      this.table.search = searching;
      this.table.page = 1;
      this.getData();
    },
    handleChangePage(page) {
      this.table.page = page;
      this.getData();
    },
    handleSort(key, active) {
      this.table.order = key;
      this.table.sort = active;
      this.getData();
    },
    handleChangelength(val) {
      this.table.length = val == "All" ? this.table.total : val;
      this.table.page = 1;
      this.getData();
    },
    handleClose() {
      // back to list
      // this.$router.push({ name: "customer-order" });
      this.$emit("handleClose");
    },
    reloadData() {
      // back to list
      // this.$router.push({ name: "customer-order" });
      this.$emit("reloadData");
    },
    handleCreate() {
      this.id = null;
      this.detail = true;
    },
    getData() {
      this.$emit("loading", true);
      this.$http
        .get(this.baseUrl, {
          params: {
            length: this.table.length,
            page: this.table.page,
            search: this.table.search,
            order: this.table.order,
            sort: this.table.sort,
          },
        })
        .then((resp) => {
          this.$emit("loading", false);
          if (resp.status == "OK") {
            this.table.total = resp.data.total_record;
            this.table.totalPage = resp.data.total_page;
            this.table.totalSearch = resp.data.total_record_search;
            this.table.length = resp.data.total_record_per_page;
            let totalPrice = 0;
            let totalTax = 0;
            // let netto = 0
            // let grossAmount = 0
            let total_disc = 0;
            this.table.data = resp.data.records;
            this.table.data.forEach((el) => {
              if (el.detail_hu) {
                el.detail_hu.forEach((el2) => {
                  // if (el2.quantity > 0) {
                  var price = parseInt(el2.price);
                  var tax = parseInt(el2.tax);
                  var disc = parseInt(el2.total_discount);
                  console.log("test", el2);
                  if (el2.is_combo) {
                    if (!this.is_combo) {
                      this.is_combo = true;
                    }
                    el2.detail_combo.forEach((el3) => {
                      var price2 = parseInt(el3.Price);
                      var tax2 = parseInt(el3.Tax);
                      var disc2 = parseInt(el3.TotalDiscount);
                      totalPrice += price2;
                      totalTax += tax2;
                      total_disc += disc2;
                      console.log("totalPrice combo", totalPrice);
                    });
                  }
                  totalPrice += price;
                  totalTax += tax;
                  total_disc += disc;
                  // }
                });
              } else {
                totalPrice += parseInt(el.total);
                totalTax += parseInt(el.tax);
                total_disc += parseInt(el.total_discount);
              }
            });
            this.totalOrder = totalPrice;
            this.TotalDisc = total_disc;
            this.grossAmount = totalPrice - total_disc;
            this.tax = totalTax;
            this.netto = totalPrice - total_disc + totalTax;
            // this.table.data = resp.data.records;
            console.log("tr.is_free_good", this.table.data[1].is_free_good);

            this.setStartEnd();
          } else {
            console.log(resp.data);
          }
        });
    },
    customLableIncoterms({ id, text }) {
      return `${text}`;
    },
    getDeliveryType() {
      this.$http.get("/api/sfa/v1/customer-order/delivery-type/all").then((resp) => {
        console.log(resp);
        if (resp.code == 200) {
          resp.data.forEach((el) => {
            this.optionIncoterms.push({
              id: el.id,
              text: el.name,
            });
          });
          var tempData = [];
          tempData = this.optionIncoterms.filter((el) => {
            return el.text == this.dataCo.delivery_type;
          });
          if (tempData.length > 0) {
            this.selectIncoterms = tempData[0];
          }
        }
      });
    },
    setStartEnd() {
      let valStart = this.table.length * this.table.page - this.table.length + 1;
      if (valStart > this.table.total) {
        valStart = 1;
      }
      if (this.table.total == 0) {
        valStart = 0;
      }
      let valEnd = this.table.length * this.table.page;
      if (valEnd > this.table.total) {
        valEnd = this.table.total;
      }
      if (this.table.totalSearch < this.table.total) {
        valEnd = this.table.totalSearch;
      }

      this.table.start = valStart;
      this.table.end = valEnd;
    },
    handleSubmitEditIncoterms() {
      this.$http
        .post("/api/sfa/v1/customer-order/edit/delivery-type/" + this.dataCo.id, {
          delivery_type_id: this.selectIncoterms.id,
        })
        .then((resp) => {
          if (resp.code == 200) {
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: "Data sent successfully",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
            this.$vs.dialog({
              type: "confirm",
              color: "success",
              title: `Confirm`,
              text: "Please confirm to reload this data",
              accept: this.reloadData,
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    handleEdit(id) {
      this.id = id;
      this.detail = true;
    },
    handleDetail(id) {
      this.id = id;
      this.detail = true;
    },
    handleExcel() {
      var code = this.dataCo.code ? this.dataCo.code : this.dataCo.ex_code;
      let paramData = {
        // Header info data
        code: code,
        personal_name: this.dataCo.personal_name,
        personal_ext_code: this.dataCo.personal_name,
        ship_to_name: this.dataCo.ship_to_name,
        ship_to_code: this.dataCo.ship_to_code,
        ship_to_address: this.dataCo.ship_to_address,
        delivery_type: this.dataCo.delivery_type,
        // Totals and line data
        customer_order_line: this.table.data,
        total_discount: this.TotalDisc,
        gross_amount: this.grossAmount,
        diskon_nota: this.discNote,
        tax: this.tax,
        netto: this.netto,
      };

      // additional, unit_item_name & sku_code
      for (let i = 0; i < paramData.customer_order_line.length; i++) {
        paramData.customer_order_line[i].unit_item = this.dataCo.customer_order_line[
          i
        ].unit_item;
        paramData.customer_order_line[i].sku_code = this.dataCo.customer_order_line[
          i
        ].sku_code;
      }

      // console.log("paramData", paramData)

      const currentDate = `${moment().format("YYYY-MM-DD")}`;
      var fileTitle = `DETAIL_CUSTOMER_ORDER_${code}`;
      this.$http
        .get(
          `api/sfa/v1/customer-order/export-excel-detail/${this.id}?datas=` +
            JSON.stringify(paramData),
          {
            responseType: "arraybuffer",
            headers: {
              Accept: "application/octet-stream",
            },
          }
        )
        .then((resp) => {
          if (resp.status == "error") {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            var fileURL = window.URL.createObjectURL(new Blob([resp]));
            var fileLink = document.createElement("a");
            fileLink.href = fileURL;

            fileTitle = fileTitle + ".xlsx";

            fileLink.setAttribute("download", fileTitle);
            document.body.appendChild(fileLink);
            fileLink.click();
          }
        });
    },
  },
  filters: {
    formatDate(value) {
      if (value) {
        return moment(String(value)).format("DD MMM YYYY");
      }
    },
    formatCurrency(value) {
      if (value) {
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
  },
  watch: {},
  mounted() {
    this.id = this.coID;
    this.baseUrl = this.baseUrl + this.id;
    this.getData();
    this.getDeliveryType();
  },
  computed: {
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        this.handleChangePage(val);
      },
    },
  },
};
</script>

<style scoped>
.badge.badge-success {
  background-color: #28c76f;
}
.badge {
  font-weight: 400;
  color: #fff;
  background-color: #7367f0;
}
.badge-success {
  color: #fff;
  background-color: #28c76f;
}
.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}
.core-enter-active {
  transition: all 0.3s ease;
}
.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.core-enter, .core-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.core {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}
.vertical-divider {
  border-left: 1px solid #7367f0;
  min-height: 800px;
  padding: 5px;
}
.vs-con-table.stripe .footer-customer-order-top-line,
.footer-customer-order-top-line:hover {
  background: white !important;
  transform: translateY(0px) !important;
  border-top: 1px solid #ddd7d7 !important;
}
.vs-con-table.stripe .footer-customer-order,
.tr.vs-con-table.stripe.footer-customer-order:hover {
  background: white !important;
  transform: translateY(0px) !important;
}
</style>
